import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import React, { Fragment } from 'react'

import styles from './SQCombobox.module.scss'

export type Option = {
    id: number | string
    name: string
}

interface SQComboboxProps {
    options: string[]
    selected: string[]
    onSelect: (options: string[]) => void
    multiple?: boolean
    placeholder?: string
}

export default function SQCombobox({ options, onSelect, selected, multiple, placeholder }: SQComboboxProps) {
    const _options = options.map((s) => {
        return {
            id: s.toLowerCase(),
            name: s,
        }
    })

    const _selectedOptions = selected.map((s) => {
        return {
            id: s.toLowerCase(),
            name: s,
        }
    })

    function handleOnClick(options: Option[] | Option) {
        if (options instanceof Array) {
            options.forEach((option) => {
                if (selected.includes(option.name)) {
                    onSelect(selected.filter((o) => o !== option.name))
                } else {
                    onSelect([...selected, option.name])
                }
            })
        } else {
            onSelect([options.name])
        }
    }

    const toggleBtn = React.useRef<HTMLButtonElement>(null)

    return (
        <Combobox
            value={_selectedOptions}
            onChange={(e) => {
                handleOnClick(e)
            }}
            /* @ts-ignore */
            multiple={multiple}
            /* @ts-ignore */
            nullable={true}
        >
            {({ open }) => (
                <>
                    <div className="relative">
                        <Combobox.Input
                            displayValue={(options: Option | Option[]) => {
                                if (options instanceof Array) {
                                    return options?.map((option: Option) => option.name).join(', ')
                                } else {
                                    return options?.name
                                }
                            }}
                            as={Fragment}
                        >
                            {() => (
                                <input
                                    placeholder={placeholder}
                                    readOnly
                                    className={styles.input}
                                    onClick={() => toggleBtn.current!.click()}
                                    type="text"
                                />
                            )}
                        </Combobox.Input>
                        <Combobox.Button ref={toggleBtn} className="absolute inset-y-0 right-0 pr-2">
                            <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
                        </Combobox.Button>
                    </div>
                    <Transition
                        show={open}
                        className="relative z-[2]"
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100 z-10"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Combobox.Options className={styles.options} static>
                            {_options.map((option: Option) => (
                                <Combobox.Option key={option.id} value={option} as={Fragment}>
                                    {() => (
                                        <li key={option.id} className={`${styles.option}`}>
                                            {selected.includes(option.name) && (
                                                <CheckIcon className={`${styles.icon}`} />
                                            )}
                                            {option.name}
                                        </li>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    </Transition>
                </>
            )}
        </Combobox>
    )
}
