import React, { createContext, useState } from 'react'

import profiling, { INITIAL_STATE, ValidationState } from '@/lib/@core/validator/formValidation.entity'

type FormValidationContextProps = {
    nextStep(): void
    prevStep(): void
    enrich(key: string, value: any): void
    validate(step: number): void
    validateAll(): void
    validateEntry(step: number, entry: string): void
    isValidated(step?: number): boolean
    clean(): void
    state: ValidationState
}

export const FormValidationContext = createContext<FormValidationContextProps>({
    nextStep: () => {},
    prevStep: () => {},
    enrich: () => {},
    validate: () => {},
    validateAll: () => {},
    validateEntry: () => {},
    isValidated: () => false,
    clean: () => {},
    state: INITIAL_STATE,
})

interface FormValidationProviderProps extends ValidationState {
    children: React.ReactNode
}

//scheme validator formating types
//required() - forces field to be non-empty
//email() - format: [string]@[string].[string]
//optional() - any value accepted
//minLength(min: number) - forces field to have a minimum length of `number` characters
//phone() - format: 11 numbers without any special characters
export const FormValidationProvider = ({ children, ...rest }: FormValidationProviderProps) => {
    const [state, setState] = useState<ValidationState>(rest)

    const nextStep = () => setState((state) => profiling.nextStep(state))

    const prevStep = () => setState((state) => profiling.prevStep(state))

    const validateAll = () => setState((state) => profiling.validateAll(state))

    const validate = (step: number) => setState((state) => profiling.validateStep(state, step))

    const enrich = (key: string, value: any) => setState((state) => profiling.enrich(state, key, value))

    const clean = () => setState((state) => profiling.clean(state))

    const isValidated = (step?: number) => profiling.isValidated(state, step)

    const validateEntry = (step: number, entry: string) =>
        setState((state) => profiling.validateEntry(state, step, entry))

    return (
        <FormValidationContext.Provider
            value={{
                state,
                nextStep,
                prevStep,
                validateAll,
                validate,
                validateEntry,
                enrich,
                isValidated,
                clean,
            }}
        >
            {children}
        </FormValidationContext.Provider>
    )
}
