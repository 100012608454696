'use client'

import { XMarkIcon } from '@heroicons/react/20/solid'
import React, { useEffect, useState } from 'react'
import { AuthContext } from 'src/context/auth'

import SQButton from '@/components/_shared/SQButton/SQButton'
import SQModal from '@/components/_shared/SQModal/SQModal'
import Login from '@/components/auth/AuthModal/login'
import Profile from '@/components/auth/AuthModal/profile'

import styles from './AuthModal.module.scss'

function Index() {
    const tabs = {
        login: 0,
        profile: 1,
    }

    const { showLoginModal, toggleLoginModal, auth } = React.useContext(AuthContext)
    const [tab, setTab] = useState(tabs.login)

    useEffect(() => {
        if (!auth.user) {
            return
        }

        if (!auth.user.profile_completed) {
            if (!showLoginModal) {
                toggleLoginModal!()
                setTab(tabs.profile)
            }
        } else {
            setTab(tabs.login)
        }
    }, [auth])

    function goBackToLogin() {
        setTab(tabs.login)
    }

    if (!showLoginModal) {
        return null
    }

    return (
        <SQModal
            className={styles.Modal}
            onEscapeKey={() => {
                if (tab === tabs.login) {
                    toggleLoginModal!()
                }
            }}
            isOpen={showLoginModal}
            width={'sm'}
        >
            <>
                {tab === tabs.login && (
                    <div className={styles.title}>
                        <SQButton className="p-0" tabIndex={10} variant="secondary" onClick={() => toggleLoginModal!()}>
                            <XMarkIcon height={24} />
                        </SQButton>
                    </div>
                )}
                {tab === tabs.login ? <Login isPage={false} /> : <></>}
                {tab === tabs.profile ? <Profile goBackToLogin={goBackToLogin} /> : <></>}
            </>
        </SQModal>
    )
}

export default Index
