export type ValidationState = {
    pages: number
    data: {
        [key: string]: any
    }
    step: number
    errors: {
        [key: string]: any
    }
    scheme: {
        [step: number]: {
            [key: string]: Validator<string | number | boolean>
        }
    }
}

export const INITIAL_STATE: ValidationState = {
    pages: 1,
    data: {},
    step: 1,
    errors: {},
    scheme: {},
}

export type Error = {
    [key: string]: string
}

function nextStep(state: ValidationState): ValidationState {
    const { step, pages } = state
    if (step >= pages) {
        return state
    }
    return { ...state, step: step + 1 }
}

function prevStep(state: ValidationState): ValidationState {
    const { step } = state
    if (step <= 1) {
        return state
    }
    return { ...state, step: step - 1 }
}

function enrich(state: ValidationState, key: string, value: any): ValidationState {
    return { ...state, data: { ...state.data, [key]: value } }
}

function clean(state: ValidationState): ValidationState {
    return { ...state, data: {} }
}

function validateStep(state: ValidationState, step: number): ValidationState {
    const { scheme, data } = state
    const errors = Object.keys(scheme[step]).reduce((acc, key) => {
        const validator = scheme[step][key]
        const error = validator(data[key])
        if (error) {
            acc[key] = error
        }
        return acc
    }, {} as Error)
    return { ...state, errors }
}

function validateAll(state: ValidationState): ValidationState {
    const { scheme, data } = state
    const errors = Object.keys(scheme).reduce((acc, step) => {
        const stepNumber = parseInt(step)
        const stepErrors = Object.keys(scheme[stepNumber]).reduce((acc, key) => {
            const validator = scheme[stepNumber][key]
            const error = validator(data[key])
            if (error) {
                acc[key] = error
            }
            return acc
        }, {} as Error)
        return { ...acc, ...stepErrors }
    }, {} as Error)
    return { ...state, errors }
}

function validateEntry(state: ValidationState, step: number, entry: string) {
    const { scheme, data, errors } = state
    const validator = scheme[step][entry]
    errors[entry] = validator(data[entry])
    return { ...state, ...errors }
}

function isValidated(state: ValidationState, step?: number): boolean {
    const { errors } = step ? validateStep(state, step) : validateAll(state)
    return Object.keys(errors).length === 0
}

const formValidationInterface = {
    nextStep,
    prevStep,
    enrich,
    clean,
    validateStep,
    validateAll,
    validateEntry,
    isValidated,
}
export default formValidationInterface

type Validator<T> = (value: T) => string | null

export function required<T>(): Validator<T> {
    return (value: any) => {
        if (value instanceof Array && value.length === 0) {
            return ErrorMessage.REQUIRED
        }

        if (!value) {
            return ErrorMessage.REQUIRED
        }
        return ''
    }
}

export function email<T>(): Validator<T> {
    return (value: any) => {
        if (!value) {
            return ErrorMessage.REQUIRED
        }

        if (!isValidEmail(value)) {
            return ErrorMessage.EMAIL
        }
        return ''
    }
}

export function optional<T>(): Validator<T> {
    return (_: any) => {
        return ''
    }
}

export function minLength<T>(min: number): Validator<T> {
    return (value: any) => {
        if (!value) {
            return ErrorMessage.REQUIRED
        }
        if (value.length < min) {
            return ErrorMessage.MIN_LENGTH
        }
        return ''
    }
}

export function phone<T>(): Validator<T> {
    return (value: any) => {
        if (!value) {
            return ErrorMessage.REQUIRED
        }
        const num = phoneToNumbers(value)
        const re = /^\d{10,11}$/
        if (!re.test(num)) {
            return ErrorMessage.PHONE
        }
        return ''
    }
}

export function scheduleDay<T>(): Validator<T> {
    return (value: any) => {
        if (value instanceof Array && value.length === 0) {
            return ErrorMessage.SCHEDULEDAY
        }

        if (!value) {
            return ErrorMessage.SCHEDULEDAY
        }
        return ''
    }
}

export const phoneToNumbers = (phone: string) => {
    return phone.replace(/\D/g, '')
}

export const isValidEmail = (email: string): boolean => {
    // sources:
    // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
    // https://emailregex.com/
    // https://datatracker.ietf.org/doc/html/rfc5322
    // see test cases for examples in formValidation.entity.spec.ts
    const dotComTypo = /^.*\.(conm|comn|con|cm|cn|om|on|co)$/gm
    const dotComDotBrType = /^.*\.(comn\.br|conm\.br|con\.br|cm\.br|cn\.br|om\.br|on\.br|co\.br|)$/gm
    const mailTypo = /^.*@(g|hot)(mali|mial|mai|mal|mil|ail|ma|mi|ml|ai|al|il|m|a|i|l)\..*$/gm

    if (dotComTypo.test(email) || dotComDotBrType.test(email) || mailTypo.test(email)) {
        return false
    }

    const re =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]*[a-z0-9!#$%&'*+/=?^_`{|}~.-]+[a-z0-9!#$%&'*+/=?^_`{|}~-]+@([a-z0-9]+[\w-]*[a-z0-9]+\.)+[\w-]{2,}$/
    return re.test(email)
}

export enum ErrorMessage {
    REQUIRED = 'Este campo é obrigatório',
    EMAIL = 'Insira um email válido',
    MIN_LENGTH = 'Este campo deve ter no mínimo 3 caracteres',
    PHONE = 'Insira um telefone válido (10 dígitos)',
    SCHEDULEDAY = 'Escolha uma data acima. Se precisar, use as setas para ver mais opções.',
}
