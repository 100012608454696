import Image from 'next/image'
import wppIcon from 'public/whatsapp.png'
import React, { useRef, useState } from 'react'

import SQButton from '@/components/_shared/SQButton/SQButton'
import SQInput from '@/components/_shared/SQInput/SQInput'
import { OTPProvider, requestOTPPreview } from '@/lib/@core/auth/auth.gateway'

import styles from './OTP.module.scss'

interface PhoneInputProps {
    onSend(): void
    onError(): void
    onIncorrectPhone(): void
    clearError(): void
    onChange(fullPhone: string): void
}

function PhoneInput({ onSend, onChange, onError, onIncorrectPhone, clearError }: PhoneInputProps) {
    const [phone, setPhone] = useState('')
    const [ddd, setDDD] = useState('')
    const phoneRef = useRef<HTMLInputElement>(null)
    const dddRef = useRef<HTMLInputElement>(null)

    function submit(provider: OTPProvider) {
        clearError()
        const fullPhone = `${ddd}${phone}`
        if (fullPhone.length !== 10 && fullPhone.length !== 11) {
            onIncorrectPhone()
            return
        }
        onChange(fullPhone)

        requestOTPPreview(fullPhone, provider)
            .then(() => {
                onSend()
            })
            .catch(() => {
                onError()
            })
        return
    }

    function onChangeDDD(v: string) {
        setDDD(v)
        if (v.length === 2) {
            phoneRef.current?.focus()
        }
    }

    function onChangePhone(v: string) {
        setPhone(v)
        if (v.length === 0) {
            dddRef.current?.focus()
        }
    }

    return (
        <div className={styles.OTP}>
            <div className={styles.phoneInput}>
                <SQInput
                    type="tel"
                    ref={dddRef}
                    className={styles.inputDDD}
                    title="DDD"
                    maxLength={2}
                    onChange={(e) => onChangeDDD(e.target.value)}
                />
                <SQInput
                    type="tel"
                    ref={phoneRef}
                    className={styles.inputPhone}
                    title="Telefone"
                    maxLength={9}
                    onChange={(e) => onChangePhone(e.target.value)}
                />
            </div>
            <div className={styles.submit}>
                <SQButton onClick={() => submit('whatsapp')} size="small" disabled={false} className={styles.btnSubmit}>
                    Receber código por Whatsapp
                    <Image alt={'ícone do whatsapp'} src={wppIcon} height={20} width={20} className="ml-2 invert" />
                </SQButton>
                <SQButton
                    onClick={() => submit('sms')}
                    size="small"
                    variant="tertiary"
                    className={'w-full justify-center'}
                >
                    Receber código por SMS
                </SQButton>
            </div>
        </div>
    )
}

export default PhoneInput
