import React from 'react'
import OtpInput from 'react-otp-input'

import styles from './SQOTP.module.scss'

interface SQOTPProps {
    len: number
    value: string
    onChange(otp: string): void
}

function SQOTP({ len, onChange, value }: SQOTPProps) {
    return (
        <OtpInput
            shouldAutoFocus
            containerStyle={styles.SQOTP}
            value={value}
            onChange={(otp) => onChange(otp)}
            numInputs={len}
            inputType="tel"
            inputStyle={`${styles.SQOTPInput} ${styles.input}`}
            renderInput={(props) => {
                return <input {...props} />
            }}
        />
    )
}

export default SQOTP
