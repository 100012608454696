import React, { useContext, useState } from 'react'
import { AuthContext } from 'src/context/auth'

import SQButton from '@/components/_shared/SQButton/SQButton'
import SQOTP from '@/components/_shared/SQOTP'
import SQSpinner from '@/components/_shared/SQSpinner/SQSpinner'
import { EventType, GetLoginFluxEventProperties, track } from '@/components/tracks'
import { confirmOTPPreview } from '@/lib/@core/auth/auth.gateway'
import { AuthState } from '@/lib/@core/auth/auth.model'

import styles from './OTP.module.scss'

interface ConfirmPhoneProps {
    phone: string
    onSuccess(data: AuthState): void
}

function ConfirmPhone({ phone, onSuccess }: ConfirmPhoneProps) {
    const [code, setCode] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const { setLoginStep } = useContext(AuthContext)

    function submit() {
        setIsLoading(true)
        confirmOTPPreview(phone, code)
            .then((res) => {
                onSuccess(res)
            })
            .catch(() => {
                setError('Código invalido! Por favor confira o código inserido.')
                track(EventType.LoginMismatchToken, GetLoginFluxEventProperties(phone))
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <div className={styles.Confirm}>
            <SQOTP len={6} onChange={(v) => setCode(v)} value={code} />
            {error.length > 0 && <p className={styles.error}>{error}</p>}
            <p className={styles.notReceived} onClick={() => setLoginStep!(3)}>
                Não recebi o código
            </p>
            <SQButton
                size="small"
                className={styles.btnSubmit}
                onClick={() => {
                    if (isLoading) {
                        return
                    }
                    submit()
                }}
            >
                <>{isLoading && <SQSpinner />} Continuar </>
            </SQButton>
        </div>
    )
}

export default ConfirmPhone
