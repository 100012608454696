import React, { useContext } from 'react'

import SQButton from '@/components/_shared/SQButton/SQButton'
import SQLink from '@/components/_shared/SQLink/SQLink'
import { ButtonTrackClickType, EventType, GetButtonEventProperties, track } from '@/components/tracks'

import { AuthContext } from '../../../../context/auth'
import styles from './OTP.module.scss'

interface NotReceivedProps {
    phone: string
}

function NotReceived({ phone }: NotReceivedProps) {
    const { setLoginStep } = useContext(AuthContext)

    return (
        <div className={styles.NotReceived}>
            <h2 className={styles.title}>Não recebeu o código?</h2>
            <p className={styles.description}>Confira se você digitou o número de celular correto.</p>
            <p className={styles.description}>Caso esteja correto, tente novamente sem o primeiro 9</p>
            <p className={styles.phone}>+55 {phone}</p>
            <p onClick={() => setLoginStep!(1)} className={styles.changeNumber}>
                Alterar número de celular
            </p>

            <SQLink
                href="https://wa.me/5511942246353?text=Olá, não recebi meu código de login."
                target="_blank"
                rel="noopener noreferrer"
            >
                <SQButton
                    size="small"
                    className={styles.submit}
                    onClick={() => {
                        track(
                            EventType.ButtonClick,
                            GetButtonEventProperties(
                                'Não recebi o código de login - fale com a gente',
                                ButtonTrackClickType.NotReceivedContactUs
                            )
                        )
                    }}
                >
                    Fale com a gente{' '}
                    <img alt={'whatsapp icon'} src="/whatsapp.png" height={20} width={20} className="ml-2 invert" />
                </SQButton>
            </SQLink>
        </div>
    )
}

export default NotReceived
