import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { captureException } from '@sentry/nextjs'
import { CouponBadge, Spinner } from '@sq/components'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { AuthContext } from 'src/context/auth'
import { FormValidationContext, FormValidationProvider } from 'src/context/formValidation'

import SQButton from '@/components/_shared/SQButton/SQButton'
import SQCombobox from '@/components/_shared/SQCombobox/SQCombobox'
import SQInput from '@/components/_shared/SQInput/SQInput'
import styles from '@/components/auth/AuthModal/Profile.module.scss'
import {
    ButtonTrackClickType,
    EventType,
    GetButtonEventProperties,
    GetInputEventProperties,
    InputTrackClickType,
    track,
} from '@/components/tracks'
import { useCoupon } from '@/components/tracks/useCoupon.hook'
import { ProfileBusinessTypes, ProfileDealDeadlines, User } from '@/lib/@core/user/user.model'
import { createOrUpdateProfile } from '@/lib/@core/user/user.usecase'
import { email, minLength, phone, required } from '@/lib/@core/validator/formValidation.entity'

function ProfileWrapper({ goBackToLogin }: ProfileProps) {
    const initialData = {
        name: '',
        email: '',
        phone: '',
        business_type: '',
        deal_deadline: '',
        referral_source: '',
        optIn: false,
    }
    return (
        <FormValidationProvider
            pages={1}
            data={initialData}
            errors={{}}
            step={1}
            scheme={{
                [1]: {
                    name: minLength(2),
                    phone: phone(),
                    email: email(),
                    business_type: required(),
                    deal_deadline: required(),
                },
            }}
        >
            <Profile goBackToLogin={goBackToLogin} />
        </FormValidationProvider>
    )
}

interface ProfileProps {
    goBackToLogin(): void
}

function Profile({ goBackToLogin }: ProfileProps) {
    const { auth, toggleLoginModal, setUser, logout } = useContext(AuthContext)
    const [customError, setCustomError] = useState<{ [key: string]: string }>({})
    const [needPhone, setNeedPhone] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const coupon = useCoupon()

    const {
        state: { data, errors, step },
        enrich,
        validate,
        isValidated,
        validateEntry,
    } = useContext(FormValidationContext)

    const businessTypeIsOther = data['business_type'] === 'Outros'
    const businessTypeOtherInput = useRef<HTMLInputElement>(null)

    const submit = () => {
        const profile = {
            ...data,
            business_type_outro: businessTypeOtherInput.current?.value,
            coupon: coupon ? { code: coupon } : undefined,
        } as User

        validate(step)
        if (isValidated(step)) {
            setIsLoading(true)
            createOrUpdateProfile(profile)
                .then((u) => {
                    setIsLoading(false)
                    if ('error' in u) {
                        setCustomError({
                            [u.key ?? 'error']: u.error,
                        })
                        return
                    }
                    setUser(u)
                    toggleLoginModal()
                })
                .catch((err) => {
                    setIsLoading(false)
                    toast.error('Erro ao criar ou atualizar o perfil.')
                    captureException(err)
                })
        }
    }

    const goBack = () => {
        trackGoBackButtonClick()
        logout()
        goBackToLogin()
    }

    const onSelectBusinessType = (option: string[]) => {
        if (option[0] === 'Outros') {
            setCustomError({
                business_type_other: 'Este campo é obrigatório',
            })
        }
        enrich('business_type', option[0])
        validateEntry!(1, 'business_type')
        trackBusinessTypeInput(option[0])
    }

    const onSelectDealDeadline = (option: string[]) => {
        enrich('deal_deadline', option[0])
        validateEntry!(1, 'deal_deadline')
        trackDealDeadlineInput(data['deal_deadline'])
    }

    const onBlurBusinessTypeOther = () => {
        if (businessTypeOtherInput.current?.value !== '') {
            setCustomError({ business_type_other: '' })
        }
        trackBusinessTypeOtherInput(data['business_type_other'])
    }

    useEffect(() => {
        const user = auth.user
        if (user) {
            enrich('name', user?.name)
            enrich('email', user?.email)
            user?.phone === '' ? setNeedPhone(true) : (setNeedPhone(false), enrich('phone', user?.phone))
        }
    }, [auth])

    return (
        <div className={styles.Profile}>
            <div className={styles.profileTitle}>
                <SQButton className="mr-2 p-0" variant="secondary" onClick={() => goBack()}>
                    <ArrowLeftIcon style={{ height: '24px' }} />
                </SQButton>
                <p>Concluir cadastro</p>
            </div>
            <div className={styles.content}>
                <span className={styles.error}>{errors.error}</span>
                <form className="flex w-full flex-col gap-2">
                    <SQInput
                        autoFocus
                        id="name"
                        type="text"
                        title="Nome completo"
                        placeholder="Digite seu nome"
                        error={errors.name}
                        value={data['name'] || ''}
                        onBlur={() => trackProfileNameInput(data['name'])}
                        onChange={(e) => enrich('name', e.target?.value)}
                    />
                    <div className={`${needPhone ? '' : 'hidden'}`}>
                        <SQInput
                            id="phone"
                            type="text"
                            title="Telefone"
                            disabled={!needPhone}
                            placeholder="Digite seu telefone"
                            error={errors.phone || customError.phone}
                            value={data['phone'] || ''}
                            onBlur={() => trackProfilePhoneInput(data['phone'])}
                            onChange={(e) => enrich('phone', e.target?.value)}
                        />
                    </div>
                    <SQInput
                        id="email"
                        type="email"
                        title="E-mail"
                        placeholder="Digite seu e-mail"
                        autoCapitalize="off"
                        autoComplete="on"
                        error={errors.email || customError.email}
                        value={data['email']}
                        onBlur={() => trackProfileEmailInput(data['email'])}
                        onChange={(e) => enrich('email', e.target?.value.trim())}
                    />
                    <div
                        id="business_type"
                        className={styles.formQuestion}
                        onClick={() => {
                            document.getElementById('business_type')?.scrollIntoView()
                        }}
                    >
                        <label className={styles.inputLabel}>Tipo de négocio</label>
                        <SQCombobox
                            options={ProfileBusinessTypes}
                            placeholder="Você procura imóvel para qual negócio?"
                            selected={[data['business_type'] || '']}
                            onSelect={onSelectBusinessType}
                        />
                        <span className={styles.error}>{errors.business_type}</span>
                        <span className={styles.inputTip}>
                            Isso vai nos ajudar a selecionar os melhores pontos comerciais para você.
                        </span>
                    </div>
                    {businessTypeIsOther && (
                        <SQInput
                            ref={businessTypeOtherInput}
                            type="text"
                            title=""
                            placeholder="Você está procurando imóvel para qual negócio?"
                            error={customError.business_type_other}
                            onBlur={onBlurBusinessTypeOther}
                        />
                    )}
                    <span className={styles.error}>{errors.business_type_other}</span>
                    <div
                        id="deal_deadline"
                        className={styles.formQuestion}
                        onClick={() => {
                            document.getElementById('deal_deadline')?.scrollIntoView()
                        }}
                    >
                        <label className={styles.inputLabel}>Em quanto tempo pretende alugar seu imóvel</label>
                        <SQCombobox
                            options={ProfileDealDeadlines}
                            placeholder="Clique para escolher"
                            selected={[data['deal_deadline'] || '']}
                            onSelect={onSelectDealDeadline}
                        />
                    </div>
                    <span className={styles.error}>{errors.deal_deadline}</span>
                    {coupon && (
                        <CouponBadge
                            code={coupon}
                            variant="only-code"
                            link="https://blog.suaquadra.com.br/explorar/codigo-desconto-indicacao/"
                        />
                    )}

                    <div className="mb-4 mt-10 flex gap-2">
                        <input
                            id="opt-in"
                            type="checkbox"
                            value={data['optIn'] || false}
                            onChange={(e) => enrich('optIn', e.target?.checked)}
                        />
                        <label htmlFor="opt-in" className="text-[14px]">
                            Concordo em receber comunicações da SuaQuadra.
                        </label>
                    </div>
                </form>
            </div>

            <div className={styles.footer}>
                <SQButton
                    size="small"
                    disabled={data['optIn'] === false || isLoading}
                    className={styles.profileSubmitButton}
                    onClick={submit}
                >
                    Continuar
                    <Spinner isVisible={isLoading} />
                </SQButton>
            </div>
        </div>
    )
}

export default ProfileWrapper

const trackGoBackButtonClick = () => {
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('user_profile_back_button', ButtonTrackClickType.UserProfileBackButton)
    )
}

const trackProfileNameInput = (name: string) => {
    track(EventType.InputBlur, GetInputEventProperties(`Nome: ${name}`, InputTrackClickType.ProfileName))
}

const trackProfilePhoneInput = (phone: string) => {
    track(EventType.InputBlur, GetInputEventProperties(`Telefone: ${phone}`, InputTrackClickType.ProfilePhone))
}

const trackProfileEmailInput = (email: string) => {
    track(EventType.InputBlur, GetInputEventProperties(`Email: ${email}`, InputTrackClickType.ProfileEmail))
}

const trackBusinessTypeInput = (businessType: string) => {
    track(
        EventType.InputBlur,
        GetInputEventProperties(`Business type: ${businessType}`, InputTrackClickType.ProfileBusinessType)
    )
}

const trackBusinessTypeOtherInput = (businessTypeOther: string) => {
    track(
        EventType.InputBlur,
        GetInputEventProperties(
            `Other business type: ${businessTypeOther}`,
            InputTrackClickType.ProfileBusinessTypeOther
        )
    )
}

const trackDealDeadlineInput = (dealDeadline: string) => {
    track(
        EventType.InputBlur,
        GetInputEventProperties(`Deal deadline: ${dealDeadline}`, InputTrackClickType.ProfileDealDeadline)
    )
}
