import { ReactNode } from 'react'
import { FocusOn } from 'react-focus-on'

import styles from './SQModal.module.scss'

interface SQModal {
    children: ReactNode
    className?: string
    isOpen: boolean
    onClickOutside?(): void
    onEscapeKey?(): void
    onLoad?(): void
    fullScreen?: boolean
    width?: 'sm' | 'md' | 'lg'
}

const modalWidth = {
    sm: 'md:w-[420px] w-full',
    md: 'md:w-[530px] w-full',
    //TODO: consider deleting lg v 1.4 changed modal size from 600 to 530 px
    lg: 'md:w-[600px] w-full',
}

function SQModal({
    children,
    isOpen,
    onClickOutside,
    onEscapeKey,
    className,
    fullScreen,
    onLoad,
    width = 'lg',
}: SQModal) {
    return (
        <div className={`${styles.SQModal} ${isOpen ? styles.show : ''} `} onLoad={onLoad}>
            {isOpen && (
                <FocusOn
                    onClickOutside={() => {
                        onClickOutside && onClickOutside()
                    }}
                    onEscapeKey={() => {
                        onEscapeKey && onEscapeKey()
                    }}
                    className={`
                        ${styles.dialog} 
                        ${fullScreen ? styles.fullScreen : modalWidth[width]}
                    `}
                    scrollLock={false}
                >
                    <div
                        className={`
                    ${styles.content} 
                    ${className} 
                    `}
                    >
                        {children}
                    </div>
                </FocusOn>
            )}
        </div>
    )
}

export default SQModal
