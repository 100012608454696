import { cn } from '@sq/components'
import React, { ForwardedRef } from 'react'

import styles from './SQInput.module.scss'

interface SQInputProps extends React.InputHTMLAttributes<HTMLInputElement & HTMLTextAreaElement> {
    type?: string
    title?: string | React.ReactNode | any
    error?: string
    prefix?: string
    suffix?: string
    labelStyle?: string
    multiline?: boolean
}
const SQInput = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, SQInputProps>(
    ({ title, type = 'text', error, prefix, suffix, labelStyle, multiline = false, ...props }, ref) => {
        function checkNumber(e: React.KeyboardEvent<HTMLInputElement>) {
            if (e.key === 'Backspace' || e.key === 'Delete') return
            const testNumber = /^[0-9]$/.test(e.key)
            if (!testNumber) {
                e.preventDefault()
            }
        }

        return (
            <>
                <div className={styles.SQInput}>
                    {title !== '' && (
                        <label htmlFor={`sq-input ${props?.id}`} className={labelStyle ?? styles.label}>
                            {title}
                        </label>
                    )}
                    <div
                        className={cn(styles.inputWrapper, props.value && styles.filled, !!error && styles.inputError)}
                    >
                        {prefix && <span className={styles.prefix}>{prefix}</span>}
                        {multiline ? (
                            <textarea
                                ref={ref as ForwardedRef<HTMLTextAreaElement>}
                                {...props}
                                className={cn(styles.input, props.className, !!error && styles.inputError)}
                                id={`sq-input ${props?.id}`}
                            />
                        ) : (
                            <input
                                ref={ref as ForwardedRef<HTMLInputElement>}
                                {...props}
                                type={type}
                                className={cn(
                                    styles.input,
                                    props.className,
                                    type === 'number' ? styles.number : '',
                                    !!error && styles.inputError
                                )}
                                id={`sq-input ${props?.id}`}
                                onKeyDown={(e) => {
                                    type && (type === 'number' || type === 'tel') ? checkNumber(e) : null
                                }}
                            />
                        )}
                        {suffix && <span className={styles.suffix}>{suffix}</span>}
                    </div>
                    {error && <span className={styles.error}>{error}</span>}
                </div>
            </>
        )
    }
)

SQInput.displayName = 'SQInput'
export default SQInput
