import { useContext, useEffect } from 'react'
import { AddressesContext } from 'src/context/addresses'

const useAddresses = () => {
    const { regions, logradouros, fetchAddresses } = useContext(AddressesContext)

    useEffect(() => {
        fetchAddresses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { regions, logradouros }
}

export default useAddresses
