import { useRouter, useSearchParams } from 'next/navigation'
import React, { useEffect, useState } from 'react'
import { AuthContext } from 'src/context/auth'

import Logo from '@/components/_shared/Logo'
import SQLink from '@/components/_shared/SQLink/SQLink'
import ConfirmPhone from '@/components/auth/AuthModal/otp/confirmPhone'
import NotReceived from '@/components/auth/AuthModal/otp/notReceived'
import PhoneInput from '@/components/auth/AuthModal/otp/phoneInput'
import { EventType, GetLoginFluxEventProperties, track } from '@/components/tracks'
import { AuthState } from '@/lib/@core/auth/auth.model'

import styles from './Login.module.scss'

export default function Login({ isPage }: { isPage: boolean }) {
    const { loginStep, setLoginStep, onLoginSuccess } = React.useContext(AuthContext)
    const [phone, setPhone] = useState('')
    const [error, setError] = useState('')
    const routerSearchParams = useSearchParams()
    const router = useRouter()

    function onOTPSuccess(auth: AuthState) {
        onLoginSuccess(auth).then(() => {
            if (isPage) {
                const redirect = routerSearchParams?.get('redirectUrl') || '/'
                router.push(redirect)
            }
        })
        track(EventType.LoginSuccess, GetLoginFluxEventProperties(phone))
    }

    function onRequestToken() {
        setLoginStep!(2)
        track(EventType.LoginRequestToken, GetLoginFluxEventProperties(phone))
    }

    useEffect(() => {
        setLoginStep!(1)
    }, [])
    return (
        <div className={styles.Login}>
            {loginStep === 1 && (
                <>
                    <div className={`${styles.content} ${styles.login}`}>
                        <div className={styles.logo}>
                            <Logo />
                        </div>
                        <h1 className={styles.h1}>Entre na sua conta</h1>
                        <p className={styles.p}>
                            Entre com o seu celular. Vamos te enviar um código para confirmar e você não precisará criar
                            uma senha.
                        </p>
                        <PhoneInput
                            onSend={() => onRequestToken()}
                            clearError={() => setError('')}
                            onError={() => setError('Não foi possível enviar o código para o telefone informado')}
                            onIncorrectPhone={() =>
                                setError(
                                    'Por favor preencha o DDD e o número corretamente. Formato esperado: 11 987654321 ou 11 87654321'
                                )
                            }
                            onChange={(phone) => setPhone(phone)}
                        />
                        <p className={styles.error}>{error}</p>
                    </div>
                    <div className={styles.footer}>
                        <hr />
                        <p className={styles.p}>
                            Ao continuar, você está de acordo com os{' '}
                            <SQLink href={'/termos'} className="underline lg:no-underline" target="_blank">
                                Termos de Uso
                            </SQLink>{' '}
                            e ciente da{' '}
                            <SQLink
                                href={'/politica-de-privacidade'}
                                className="underline lg:no-underline"
                                target="_blank"
                            >
                                Política de Privacidade
                            </SQLink>
                            .
                        </p>
                    </div>
                </>
            )}
            {loginStep === 2 && (
                <>
                    <div className={`${styles.content} ${styles.login}`}>
                        <h2 className={styles.h2}>Confirme seu número</h2>
                        <p className={styles.p}>
                            Insira o código de 6 digitos que enviamos para o número +55 {phone}
                            <br /> <br />
                            Caso não tenha recebido, tente o número novamente sem o primeiro 9.
                        </p>
                        <ConfirmPhone phone={phone} onSuccess={(auth) => onOTPSuccess(auth)} />
                    </div>
                </>
            )}

            {loginStep === 3 && <NotReceived phone={phone} />}
        </div>
    )
}
